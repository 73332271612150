<style scoped>
.main {
    min-width: 1200px;
    padding: 10px 100px;
}
.imgPlan {
    width: 50%;
}
</style>

<template>
    <div>
        <Header />
        <div class="main">
            <el-tabs type="border-card">
                <el-tab-pane label="Java工程师">
                    <img src="/pictures/plan/Java工程师学习路线.png" alt="Java工程师学习路线" class="imgPlan" />
                </el-tab-pane>
                <el-tab-pane label="前端工程师">
                    <img src="/pictures/plan/前端工程师学习路线.png" alt="前端工程师学习路线" class="imgPlan" />
                </el-tab-pane>
            </el-tabs>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "../common/Header.vue";
import Footer from "../common/Footer.vue";

export default {
    //注册组件
    components: {
        Header,
        Footer
    },
    data() {
        return {};
    },
    methods: {},
    mounted() {}
};
</script>
